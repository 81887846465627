<template>
  <q-layout view="hHh lpR fFf">

    <q-drawer show-if-above v-model="left" side="left" bordered>
      <div class="row justify-between q-pa-md text-center text-green-1 " style="background-color:#0C6130">
        <div>
          <div class="text-h5">
            COVID - 19
          </div>
          <div>
            Survey Monitor
          </div>
        </div>
        <div>
          <q-btn dense style="width: 60px;" label="logout" no-caps color="deep-orange-13" @click="logout" />
        </div>
      </div>
      <q-list>
        <q-item
          clickable
          v-ripple
          :active="link === 'hasil'"
          @click="onClick('hasil')"
          active-class="my-menu-link"
        >
          <q-item-section>Hasil Survey</q-item-section>
        </q-item>
        <q-item
          clickable
          v-ripple
          :active="link === 'arsip'"
          @click="onClick('arsip')"
          active-class="my-menu-link"
        >
          <q-item-section>Arsip Survey</q-item-section>
        </q-item>

        <q-item
          clickable
          v-ripple
          :active="link === 'karantina'"
          @click="onClick('karantina')"
          active-class="my-menu-link"
        >
          <q-item-section>List Karantina</q-item-section>
        </q-item>
        <q-item
          clickable
          v-ripple
          :active="link === 'data-pokok'"
          @click="onClick('data-pokok')"
          active-class="my-menu-link"
        >
          <q-item-section>Data Pokok</q-item-section>
        </q-item>
      </q-list>
      
    </q-drawer>
    <q-page-container class="" style="background-color: #EBECFC">
      <router-view />
    </q-page-container>

  </q-layout>
</template>

<script>
export default {
  data () {
    return {
      left: true,
      link: 'hasil',
      confirm: false
    }
  },
  mounted(){
    var token = localStorage.getItem('token')
    this.$http.get('/user/info', {headers: { Authorization: "Bearer " + token}})
      .then (result => {
        
        })
      .catch(error => {
        this.$router.push('/login')
      })
      this.onClick(this.link)
  },
  methods:{
    onClick(val){
      this.link = val
      this.$router.push({name: val}).catch(() => {});
    },
    logout(){
      localStorage.removeItem("token");
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>

.my-menu-link{
  color: white;
  // background: #C5CAE9;
  background-color: #03913F;
}
</style>